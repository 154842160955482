// @use "../src/utils/variables";
@import "utils/variables";

// TODO put all spectacles css on another file to keep clean
#spec-viewer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.spectacles-viewer-container {
  height: 100%;
  width: 100%;
}

.spectacles.jq-extras {
  height: 100%;
  width: 100%;
  padding-bottom: 500px;
  padding-left: 500px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 220, 222);
  border-radius: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 14px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

html,
body {
  width: 100%;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0.875rem;
  background-color: $bgcolor1;
  width: 100%;
  height: 100%;
}

body main,
html main {
  height: 100%;
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0 auto;
}

body .main,
html .main {
  height: 100%;
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0 auto;
}

.main-view {
  height: 100%;
  display: table-row;
}

.global-footer {
  display: table-row;
  position: relative;
}

.form-control::placeholder {
  font-size: 14px;
  color: #9e9e9e;
}

a {
  color: $aqua;
  text-decoration: none;
}

a {
  &:hover {
    color: $aqua4;
  }
}

main {
  height: 100%;
  width: 100%;
}

.error-text {
  font-size: 13px;
  font-weight: 405000;
  color: $danger;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.font-11 {
  font-size: 11px;
}

.font-12-1 {
  font-size: 12.5px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px;
}

.bg-yellow1 {
  background-color: $yellow1;
}

.bg-info1 {
  background-color: $bginfo1;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $yellow2 !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-faded {
  background-color: #f0f0f0;
}

.bg-success {
  background-color: $aqua !important;
}

.bg-secondary {
  background-color: $muted1 !important;
}

.bg-white {
  background-color: $white;
}

.bg-lightblue {
  background-color: $lightblue1;
}

.bg-milkyblue {
  background-color: $milkyblue;
}

.bg-lightpink {
  background-color: $lightpink;
}

.bg-extragrayone {
  background-color: $lightgray8;
}

.bg-grayone {
  background-color: $lightgray9;
}

.bg-lightgreenone {
  background-color: $lightgreen1;
}

.bg-blueone {
  background-color: $blue1;
}

.bg-orangeone {
  background-color: $orange11;
}

.bg-orangetwo {
  background-color: $orange12;
}

.bg-pinkone {
  background-color: $pink1;
}

.bg-yellowone {
  background-color: $yellow3;
}

.bg-lightblueone {
  background-color: $lightblue2;
}

.bg-extrapinkone {
  background-color: $pink2;
}


.border-aqua {
  border-color: $aqua !important;
}

.alert-info {
  background-color: $lightinfo1;
  border-color: $lightinfo2 !important;
  color: $alertinfo;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faf2cc !important;
  color: #8a6d3b;
}

.cursor-default {
  cursor: default;
}

.btn-darkgreen {
  color: $white;
  background: linear-gradient(#466a7a, #36515d) $darkgreenone;
  border: 1px solid #2c434d;
  box-shadow: $lightgrayone 0 1px 0;
  font-weight: 500;
  // font-size: ;

  &:hover {
    color: $white;
    background: linear-gradient($darkgreenone, #2a404a) #36515d;
    border-color: #23353d;
  }

  &:active {
    color: $white !important;
    background: linear-gradient(#2a404a, #324b57) #2a404a;
    border-color: #1a272c;
    box-shadow: #e9eaeb 0 1px 0, inset #182429 0 1px 2px;
  }
}

.btn-warning {
  color: $white;
  background: linear-gradient($orange3, $orange4) $orange1;
  border: 1px solid $orange5;
  box-shadow: $lightgrayone 0 1px 0;
  font-weight: 500;

  &:hover {
    color: $white;
    background: linear-gradient($orange1, $orange6) $orange4;
    border-color: $orange7;
  }

  &:active {
    color: $white !important;
    background: linear-gradient($orange6, $orange8) $orange6;
    border-color: $orange9;
    box-shadow: $lightgrayone 0 1px 0, inset $orange10 0 1px 2px;
  }

  &:focus {
    color: $white;
    background: linear-gradient($orange1, $orange4) $orange4;
    box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
  }

  &:disabled {
    color: $white;
    cursor: not-allowed;
    background-color: $orange1;
    opacity: 0.7;
    pointer-events: inherit;
  }
}

.btn-secondary {
  color: $darkgreentwo;
  background: linear-gradient($white, #f5f6f7) $white;
  border: 1px solid $lightgray;
  box-shadow: $lightgrayone 0 1px 0;

  &:hover {
    color: $darkgreentwo;
    background: linear-gradient($white, $lightwhite1) #f5f5f5;
    border-color: #c5cdd0;
  }

  &:active {
    color: $darkgreentwo !important;
  }
}

.btn-secondary {

  &.active,
  &:active {
    color: $darkgreentwo !important;
    background: linear-gradient($lightgray5, $lightgray3) $lightgray5;
    border-color: $gray10 !important;
    box-shadow: $lightgrayone 0 1px 0, inset $lightgray4 0 1px 2px;
  }
}

.dropdown.show {
  .btn-secondary {
    &.dropdown-toggle {
      color: $darkgray;
      background-color: $lightwhite1;
      background-image: none;
      border-color: $gray8;
    }
  }
}

.btn-orange {
  color: $dark1;
  background-color: $orange;
  border-color: $orange;

  &:hover {
    color: $dark1;
    background-color: $orange;
    border-color: $orange;
  }

  &:active {
    color: $dark1;
    background-color: $orange !important;
  }
}

.btn-aqua {
  color: $white;
  background: linear-gradient($aqua1, $aqua2) $aqua;
  border-color: $aqua3;
  box-shadow: $lightgrayone 0 1px 0;

  &:hover {
    color: $white;
    background: linear-gradient($aqua, $aqua5) $aqua2;
    border-color: $aqua6;
  }

  &:active {
    color: $white !important;
    background: linear-gradient($aqua5, $aqua7) $aqua5 !important;
    border-color: $aqua8 !important;
    box-shadow: $lightgrayone 0 1px 0, inset $aqua9 0 1px 2px;
  }
}

.btn-aqua[disabled] {
  color: $white;
  background-color: $aqua;
  opacity: 0.7;
}

.btn-darkgreen[disabled] {
  color: $white;
  background-color: $darkgreenone;
  opacity: 0.7;
}

.btn-info {
  color: $white;
  background: linear-gradient($info2, $info3) $info;
  border: 1px solid $info4;
  box-shadow: $lightgrayone 0 1px 0;

  &:hover {
    color: $white;
    background: linear-gradient($info, $info5) $info3;
    border-color: $info6;
  }

  &:active {
    color: $white !important;
    background: linear-gradient($info5, $info7) $info5;
    border-color: $info8 !important;
    box-shadow: $lightgrayone 0 1px 0, inset $info9 0 1px 2px;
  }

  &:focus {
    color: $white;
    background: linear-gradient($info, $info3) $info3;
    box-shadow: 0 0 0 2px rgba(20, 150, 181, 0.5);
  }
}

.btn-info[disabled] {
  color: $white;
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: unset;
}

.btn-danger {
  color: $white;
  background: linear-gradient($red1, $red2) $extramarun;
  border: 1px solid $red3;
  box-shadow: $lightgrayone 0 1px 0;

  &:hover {
    color: $white;
    background: linear-gradient($extramarun, $red4) $red2;
    border-color: $red5;
  }

  &:active {
    color: $white !important;
    background: linear-gradient($red4, $red6) $red4;
    border-color: $red7;
    box-shadow: $lightgrayone 0 1px 0, inset $red8 0 1px 2px;
  }

  &:focus {
    color: $white;
    background: linear-gradient($extramarun, $red2) $red2;
    box-shadow: 0 0 0 2px rgba(180, 21, 59, 0.5);
  }
}

.btn-danger[disabled] {
  color: $white;
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: unset;
}

.btn-default {
  background-color: #f0f0f0;
}

.btn-sm {
  padding: 0.25rem 0.6rem 0.35rem !important;
  font-size: 0.8rem !important;
}

.text-muted {
  color: $muted1 !important;
}

a.text-muted:focus,
a.text-muted:hover {
  color: $graymuted1 !important;
}

.text-muted-1 {
  color: $muted2;
}

.text-info {
  color: $aqua !important;
}

.text-info-1 {
  color: $aqua !important;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.loading-modal {
  .modal-content {
    border-radius: 3px;
    border: 1.9px solid $modalborder;
  }

  p {
    font-size: 20px;
    text-align: center;

    svg {
      margin-right: 9px;
      animation-name: spin;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modal-backdrop.show {
  opacity: 0.07;
}

.login-signup-box {
  width: 100%;
  max-width: 745px;
  margin: 0 auto;
}

.btn {
  font-size: 15px;
  font-weight: 400;
  padding: 0.407rem 0.8rem;
  text-transform: capitalize;
  border-radius: 4px;
}

.btn-lg {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}

.link-text {
  font-size: 13px;
  line-height: 2.067;
}

.container-fluid-1600 {
  max-width: 1600px;
}

.profile-tabs-nav {
  .nav-link {
    color: $aqua;

    &.active {
      background-color: $aqua;

      &:hover {
        color: $white;
      }

      &:focus {
        color: $white;
      }
    }

    &:hover {
      color: $aqua4;
    }

    &:focus {
      color: $aqua4;
    }
  }
}

.profile-tab-content {
  .card-title {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 1rem;
  }

  .seperator-line {
    height: 0;
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .avatar {
    width: 36px;
    height: 36px;
    margin-left: 0;
    vertical-align: middle;
    justify-content: center;
    background: $grayone;
    padding: 3px;
    border-radius: 3px;
    border: 1px solid $graytwo;

    img {
      width: 100%;
      max-width: 31px;
      height: 100%;
      min-height: 31px;
    }
  }

  .bg-secondary {
    background-color: $muted1 !important;
    font-size: 10px;
    line-height: 10px;
    padding-top: 0.29rem;
  }

  .list-group-item {
    padding: 0.75rem 1.25rem;
  }
}

.avatar {
  margin-left: 10px;
  width: 24px;
  height: 24px;
  font-size: 0.9em;
  line-height: 24px;
  vertical-align: middle;
  justify-content: center;

  span {
    background: $marunone;
    color: $white;
    padding: 3px;
    font-weight: 800;
    border-radius: 3px;
  }
}

.modal-title {
  font-size: 17px;
  font-weight: 400;
}

.custom-modal {
  .modal-header {
    .btn-close {
      font-size: 9px;
    }
  }
}

.custom-modal-one {
  .modal-header {
    padding: 15px;
  }

  .modal-body {
    padding: 15px;
  }

  .modal-footer {
    padding: 15px;
    border-top: 1px solid #c2c1c0;
  }
}

// toaster css start
.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.alert-danger {
  background-color: $lightdanger1;
  border-color: $lightdanger2;
  color: $errordanger;
}

.Toastify__toast-container {
  width: 67vw !important;
  top: 55px !important;
  align-items: center !important;
}

.Toastify__toast-body {
  margin: 0 0 !important;
  padding: 0 !important;
}

.Toastify__toast-container .Toastify__close-button {
  margin: auto 0;
}

.Toastify__toast {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  min-height: 47px !important;
  box-shadow: none !important;
}

.Toastify__toast-container {
  .Toastify__toast--error {
    background-color: $lightdanger1;
    border-color: $lightdanger2;
    color: $errordanger;
  }

  .Toastify__toast--success {
    background-color: $lightsuccess1;
    border-color: $lightsuccess2;
    color: $alertsuccess;
  }

  .Toastify__toast--info {
    background-color: $lightinfo1;
    border-color: $lightinfo2;
    color: $alertinfo;
  }

  .Toastify__toast-icon {
    display: none;
  }
}

// toaster css end

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.form-control:focus {
  color: $gray6;
}

.form-control {
  color: $darkgreenone;
}

.form-control,
.form-select {
  box-shadow: $lightgrayone 0 1px 0;
}

.form-control:focus,
.form-select:focus {
  border-color: $darkgreenone;
  box-shadow: rgba(61, 92, 106, 0.2) 0 0 0 3px;
}

.has-error .form-control {
  border-color: $extramarun;
  box-shadow: inset $extramarun 0 0 0 1px;

  &::placeholder {
    color: $extramarun;
  }
}

.has-error .form-control:focus {
  border-color: $extramarun;
  box-shadow: inset $extramarun 0 0 0 1px, rgba(180, 21, 59, 0.2) 0 0 0 3px;
}

.has-error .form-select {
  border-color: $extramarun;
  box-shadow: inset $extramarun 0 0 0 1px;
}

.has-error .form-select:focus {
  border-color: $extramarun;
  box-shadow: inset $extramarun 0 0 0 1px, rgba(180, 21, 59, 0.2) 0 0 0 3px;
}

.form-select {
  option {
    font-size: 14px;
    font-weight: 400;
  }
}

.form-control,
.form-select {
  font-size: 0.9rem;
  font-weight: 400;
}

.page-title {
  font-size: 1.75rem;
}
.text-transform-none {
  text-transform: inherit;
}

.modal-mt-72px {
  margin-top: 72px;
}

.modal-btn-close {
  background: 0 0;
  font-size: 1.3125rem;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  font-weight: 700;
  padding: 0;

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.75;
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.75;
    box-shadow: none;
  }
}

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4; // Example background color

  h1 {
    font-family: "Arial", sans-serif;
    font-size: 4rem;
    color: #333; // Text color
    text-transform: uppercase;
    letter-spacing: 4px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    animation: fadeIn 2s ease-in-out infinite;
    text-align: center;
    margin-bottom: 0;
  }

  @keyframes fadeIn {

    0%,
    100% {
      opacity: 0.3;
    }

    50% {
      opacity: 1;
    }
  }
}

.custom-dropdown {
  .dropdown-menu {
    .dropdown-item {

      &:focus,
      &:hover {
        background-color: $grayone;
      }

      &.active {
        background-color: $aqua;
        color: $white;
      }

      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }

    border-color: rgba(61, 92, 106, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 30px;
  }
}

.custom-dropdown .dropdown-menu .dropdown-item:hover .active {
  background-color: $aqua;
  color: $white;
}



.custom-dropdown-one {
  &::after {
    color: gray;
    width: 10px;
    height: 16px;
    background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg fill='rgba(0,0,0,0.5)'%3E %3Cpolygon id='Shape' points='5 11 0 6 1.5 4.5 5 8.25 8.5 4.5 10 6'%3E%3C/polygon%3E %3C/g%3E %3C/g%3E %3C/svg%3E") center no-repeat;
    border: 0;
    vertical-align: middle;
    margin-left: 0.5em;
    float: right;
  }

  .dropdown-menu {
    .dropdown-item {

      &:focus,
      &:hover {
        background-color: $grayone;
      }

      &.active {
        background-color: $aqua;
        color: $white;
      }

      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }

    border-color: rgba(61, 92, 106, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 30px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-dropdown-one .dropdown-menu .dropdown-item:hover .active {
  background-color: $aqua;
  color: $white;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-popover {
  .popover-arrow {
    &:after {
      border-bottom-color: #32aa9e;
    }
  }

  .popover-header {
    font-size: 1rem;
    color: #fff;
    background-color: #32aa9e;
    padding: 8px 14px;
    margin-bottom: 0;
    font-weight: 400;
    border-bottom: 1px solid #ebebeb;
    border-top-right-radius: calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
  }
}

.custom-popover-tooltip {
  margin-top: 8px;
  position: absolute;
  z-index: 1060;
  letter-spacing: normal;
  line-break: auto;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  word-break: normal;
  word-spacing: normal;
  word-wrap: break-word;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;

  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    content: "";
    border-width: 11px;
    left: 50%;
    border-top-width: 0;
    top: -11px;
    margin-left: -11px;
    border-bottom-color: $aqua;
  }

  .custom-popover-tooltip-header {
    font-size: 1rem;
    color: #fff;
    padding: 8px 14px;
    margin-bottom: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-right-radius: calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
  }

  .custom-popover-tooltip-body {
    padding: 9px 14px;
    color: #000;
  }

  &.custom-small-popover {
    border: none !important;

    .custom-popover-tooltip-header {
      border-radius: 0.3rem;
    }
  }
}

.lead-2 {
  font-size: 1.25rem;
  font-weight: 300;
}

.lead-small {
  color: #777;
  font-size: 83%;
  line-height: 2.067;
}

.custom-popover-tooltip {
  margin-top: 8px;
  position: absolute;
  z-index: 1060;
  letter-spacing: normal;
  line-break: auto;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  word-break: normal;
  word-spacing: normal;
  word-wrap: break-word;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;

  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    content: "";
    border-width: 11px;
    left: 50%;
    border-top-width: 0;
    top: -11px;
    margin-left: -11px;
    border-bottom-color: $aqua;
  }

  .custom-popover-tooltip-header {
    font-size: 1rem;
    color: #fff;
    padding: 8px 14px;
    margin-bottom: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-right-radius: calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
  }

  .custom-popover-tooltip-body {
    padding: 9px 14px;
    color: #000;
  }

  &.custom-small-popover {
    border: none !important;

    .custom-popover-tooltip-header {
      border-radius: 0.3rem;
    }
  }
}

.popover-arrow-left-30 {

  &:before,
  &:after {
    left: 30% !important;
  }
}

.popover-arrow-left-38 {

  &:before,
  &:after {
    left: 38% !important;
  }
}

.custom-popover-placingbottom {
  transform: translateY(132px);
}

.custom-alert-one {
  padding: 0.625rem;
}

.secondary-sort-dropdown {
  .dropdown-toggle {
    &::after {
      color: gray;
      width: 10px;
      height: 16px;
      background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg fill='rgba(0,0,0,0.5)'%3E %3Cpolygon id='Shape' points='5 11 0 6 1.5 4.5 5 8.25 8.5 4.5 10 6'%3E%3C/polygon%3E %3C/g%3E %3C/g%3E %3C/svg%3E") center no-repeat;
      border: 0;
      vertical-align: middle;
      margin-left: 0.5em;
    }
  }

  .btn-secondary {
    font-size: 0.925rem;

    &:active {
      background: linear-gradient($lightgray5, $lightgray3) $lightgray5;
      border-color: $gray10;
      box-shadow: $lightgrayone 0 1px 0, inset $lightgray4 0 1px 2px;
    }

    &:focus-visible {
      color: $darkgreentwo !important;
    }

    span {
      opacity: 0.6;
      font-weight: 400;
      color: #233e49b3;
    }
  }
}

.custom-form-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  background-color: $white;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  line-height: 1.3;
  color: $darkgreenone;
  resize: none;
  box-shadow: $lightgrayone 0 1px 0;

  &::placeholder {
    color: #3d5c6a80;
  }
}

.pointer-event-none {
  pointer-events: none;
}

.opacity-3 {
  opacity: 0.3;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.custom-margin-modal {
  .modal-dialog {
    margin-top: 100px;
  }
}

.border-bottom {
  border-bottom: 1px solid $grayone !important;
}

.o-7 {
  opacity: 0.7;
}


.konstru-radio div {
  clear: both;
  overflow: unset
}

.konstru-radio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: unset
}

.konstru-radio input[type=checkbox]:empty,
.konstru-radio input[type=radio]:empty {
  display: none
}

.konstru-radio input[type=checkbox]:empty~label,
.konstru-radio input[type=radio]:empty~label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.konstru-radio input[type=checkbox]:empty~label:before,
.konstru-radio input[type=radio]:empty~label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  height: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px
}

.konstru-radio input[type=checkbox]:hover:not(:checked)~label,
.konstru-radio input[type=radio]:hover:not(:checked)~label {
  color: #888
}

.konstru-radio input[type=checkbox]:hover:not(:checked)~label:before,
.konstru-radio input[type=radio]:hover:not(:checked)~label:before {
  content: '\2713';
  text-indent: .9em;
  color: #969696;
}

.konstru-radio input[type=checkbox]:checked~label,
.konstru-radio input[type=radio]:checked~label {
  color: #777
}

.konstru-radio input[type=checkbox]:checked~label:before,
.konstru-radio input[type=radio]:checked~label:before {
  content: '\2713';
  text-indent: .9em;
  color: #333;
  background-color: #ccc
}

.konstru-radio input[type=checkbox]:focus~label:before,
.konstru-radio input[type=radio]:focus~label:before {
  box-shadow: 0 0 0 3px #999
}

.konstru-radio-default input[type=checkbox]:checked~label:before,
.konstru-radio-default input[type=radio]:checked~label:before {
  color: #333;
  background-color: #ccc
}

.konstru-radio-primary input[type=checkbox]:checked~label:before,
.konstru-radio-primary input[type=radio]:checked~label:before {
  color: #fff;
  background-color: #337ab7
}

.konstru-radio-success input[type=checkbox]:checked~label:before,
.konstru-radio-success input[type=radio]:checked~label:before {
  color: #fff;
  background-color: #5cb85c
}

.konstru-radio-danger input[type=checkbox]:checked~label:before,
.konstru-radio-danger input[type=radio]:checked~label:before {
  color: #fff;
  background-color: #d9534f
}

.konstru-radio-warning input[type=checkbox]:checked~label:before,
.konstru-radio-warning input[type=radio]:checked~label:before {
  color: #fff;
  background-color: #f0ad4e
}

.konstru-radio-info input[type=checkbox]:checked~label:before,
.konstru-radio-info input[type=radio]:checked~label:before {
  color: #fff;
  background-color: #5bc0de
}

.konstru-radio.radio-single label {
  border: 0
}

.konstru-radio.radio-single input[type=checkbox]:empty:disabled~label,
.konstru-radio.radio-single input[type=radio]:empty:disabled~label {
  color: gray;
  cursor: not-allowed;
  pointer-events: inherit
}

.konstru-radio.radio-single input[type=checkbox]:hover:not(:checked):not([disabled])~label {
  color: #000
}

.konstru-radio.radio-single input[type=checkbox]:hover:disabled~label {
  cursor: not-allowed;
  pointer-events: inherit
}

.konstru-radio.radio-single input[type=checkbox]:checked~label {
  color: #000;
  font-weight: 700
}

.konstru-radio.radio-single input[type=checkbox]~label:before {
  border-radius: 3px
}

.input-group-text {
  background-color: #eceeef;
  color: #656666;
}

.form-control {
  &:disabled {
    color: #aaaaab;
    background: #f8f8f8;
    cursor: not-allowed;
  }
}

.btn:focus-visible {
  color: inherit;
  box-shadow: none;
}

.text-black-hover {
  &:hover {
    color: #216f67 !important;
  }
}

.input-group-text {
  font-size: .875rem;
  padding: 0.5rem 0.75rem;
}

.custom-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  line-height: 1;
  color: #fff;
  text-align: center;
  border-radius: 0.3rem;
}

@media (min-width: 1562px) and (max-width: 3000px) {
  .login-signup-box {
    max-width: 745px;
  }
}

@media (min-width: 1200px) and (max-width: 1563px) {
  .login-signup-box {
    max-width: 560px;
  }
}

@media (max-width: 991.98px) {
  .profile-tabs-nav {
    flex-direction: row !important;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .login-signup-box {
    max-width: 510px;
  }

  .Toastify__toast-container {
    width: 85vw !important;
  }

  .tablet-mb-10 {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .mobile-text-center {
    text-align: center !important;
  }

  .profile-tab-content {
    .card-title {
      font-size: 1.3rem;
    }
  }

  .Toastify__toast-container {
    width: 87vw !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .Toastify__toast {
    border-radius: 0.3rem !important;
  }

  .mobile-mb-10 {
    margin-bottom: 10px;
  }

  .custom-modal-one {
    .modal-header {
      .modal-title {
        font-size: 18px !important;
      }
    }
  }
}

.invite-user-dropdown {
  overflow-x: auto;
  // height: 415px !important;
  max-height: 415px !important;
}

.no-models {
  font-size: 4rem;
}

.no-models-desc {
  font-size: 1.2rem;
}