@import "utils/variables";


// Plese shift the below variable in util/variables with the help of jaimin
$bordertop: rgba(61, 92, 106, .15);


footer {
    padding: 2rem 0;
    background: $white;
    border-top: 1px solid $bordertop;

    .footer-link {
        margin-right: 1rem;
    }

    .pe-117 {
        padding-right: 117px;
    }
}

@media (max-width: 575px) {
    .mobile-d-block {
        display: block;
    }
}