.modal-body {
  padding: 2.5rem 1rem;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.loading-modal {

  .modal-dialog {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;

    .modal-content {
      border: 1.9px solid #89898975;
    }

    .modal-body {
      padding: 2.9rem 1rem;
    }
  }
  .modal-text-lead-1{
    color: #5c5858;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0rem;
  }
  .modal-text-setting-icon{
    color: #7c7c7c;
  }
}

.loading-text {
  color: #707070;
}

.loading-modal{
  .additional-modal-body-loader{
    height: 200px;
    width: 480px;
   
      .busy-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        vertical-align: middle;

      }
    
  }
}