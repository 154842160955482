@import "utils/variables";

.modal-body {
  padding: 2.5rem 1rem;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.networkloading-modal {

  .modal-dialog {
    width: 100%;
    max-width: 570px;
    margin: 0 auto;

    .modal-content {
      border: 1.9px solid #89898975;
    }

    .modal-body {
      padding: 1rem 1rem;
    }
  }
}

.networkloading-modal {
  .modal-content {
    border-radius: 3px;
    border: 1.9px solid $modalborder;
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    color: #797979;
    font-weight: 500;

    svg {
      width: 20.58px;
      height: 24px;
      font-size: inherit;
      color: inherit;
      margin-right: 9px;
      animation-name: spin;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  .network-modal-detail {
    font-size: 1rem;
  }

  span {
    color: #707070;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.fade.modal-backdrop.networkloadingmodal-backdrop.show {
  opacity: 0.1 !important;
}