// Please shift the below variable in util/variables with the help of jaimin


$darkgreen: #243f49;
$aqua: #32aa9e;


#root {
  height: 100vh;
}


body {
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a{
  text-decoration: none;
}