$darkgreen: #243f49;
$aqua: #32aa9e;
$extralight: #ffffff99;
$extralighttwo: #ffffffcc;
$marunone: #b82601;
$white: #ffffff;
$lightgray: #d3d9db;
$bgcolor1: #f8f8f8;
$muted1: #aaaaab;
$muted2: #55637d;
$darkgreentwo: #233e49;
$orange: #e87330;
$dark1: #3f2211;
$grayone: #e2e2e1;
$graytwo: #c7c7c7;
$lightgrayone: #e9eaeb;
$lightgray2: #E6EAEC;
$lightgray3: #f2f4f5;
$lightgray4: #c9d2d6;
$lightgray5: #E6EAEC;
$lightgray6: #dee2e6;
$lightgray7: #e5e5e5;
$lightgray8: #cccccc;
$lightgray9: #bbbbbb;
$lightgray10: #f0f0f0;
$lightgray11: #e8f6f9;
$lightgray12: #e7e7e7;
$darkgreenone: #3d5c6a;
$danger: #b4153b;
$aqua1: #38beb0;
$aqua2: #2d9a8f;
$aqua3: #28877d;
$aqua4: #216f67;
$aqua5: #268379;
$aqua6: #22736b;
$aqua7: #2b9288;
$aqua8: #1c5f58;
$aqua9: #1b5b55;
$aqua10: #32aa9ecc;
$black: #000000;
$orange1: #e05916;
$orange2: #ff4500;
$orange3: #ea6726;
$orange4: #cd5214;
$orange5: #b64812;
$orange6: #b24711;
$orange7: #9f3f10;
$orange8: #c44e13;
$orange9: #88360d;
$orange10: #83340d;
$orange11: #ef6c00;
$orange12: #f44336;
$plaingray: gray;
$gray1: #b1b1b1;
$gray2: #eeeeee;
$gray3: #888;
$gray4: #d8dcde;
$gray5: #f8f8f8;
$gray6: #656666;
$gray7: #f8f9f9;
$gray8: #adadad;
$gray9: #c2c1c0;
$gray10: #b7c1c4;
$lightgreen: #456877;
$green: #37525e;
$extralightyellow: #fef8f2;
$lightwhite: #f5f9e7;
$white2: #dddddd;
$white3: #f5f5f5;
$white4: #f5f5f5;
$info: #1496b5;
$modalborder: #cfcfcf;
$lightgray2: #A1A1A1;
$extramarun: #b4153b;
$black2: #2f3941;
$black3: #262626;
$marun1: #8c232c;
$lighdark: #2f3941;
$info1: #02a191;
$info2: #17a9cc;
$info3: #1287a3;
$info4: #0f748c;
$info5: #0f7087;
$info6: #0d6175;
$info7: #117f99;
$info8: #0a4e5e;
$info9: #0a4a59;
$bginfo1: #4ABDAC;
$marun2: #452513;
$lightdanger1: #f2dede;
$lightdanger2: #ebcccc;
$errordanger:#a94442;
$lightsuccess1: #dff0d8;
$lightsuccess2: #d0e9c6;
$alertsuccess: #3c763d;
$lightinfo1: #d9edf7;
$lightinfo2: #bcdff1;
$alertinfo: #31708f;
$graymuted1: #909092;
$yellow1: #D9B310;
$yellow2: #f0ad4e;
$yellow3: #d8de40;
$darkgray:#333;
$darkgray1:#4b4b4b;
$lightwhite1: #e6e6e6;
$red1:#cb1842;
$red2: #a21335;
$red3: #8b102e;
$red4: #86102c;
$red5: #740e26;
$red6: #991232;
$red7: #5d0b1f;
$red8: #590a1d;
$lightblue1: #98a6b7;
$lightblue2: #02a3d0;
$milkyblue: #7aa5b7;
$lightpink: #b6a7b7;
$lightgreen1: #a6bcb9;
$blue1: #5948a8;
$pink1: #da00b4;
$pink2: #9c27b0;
// variables-dark theme

$orange: #e87330;