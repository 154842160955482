
// Please shift the below variable in util/variables with the help of jaimin
$darkgreen: #243f49;
$aqua: #32aa9e;


.loadingscreen-overlay {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #243f49;
	color: white;
	z-index: 1065;
	text-align: center;
	background-color: #243f49;
	transition: opacity 0.5s linear, height 0.01ms linear 0.5s;
}

.logo {
	width: 100%;
	max-width: 450px;
	display: inline-block;
}

.loading-text {
	width: 100%;
	text-align: center;
	font-size: 24px;
	display: block;
	position: absolute;
	bottom: 46%;

	p {
		color: #ffff;
	}

	a {
		color: $aqua;
		text-decoration: none;
	}
}