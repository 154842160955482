@import "utils/variables";

.header-nav-menu {
  display: none !important;
}

.menu-nav.navbar {
  .navbar-toggler {
    border-color: $white;
  }
}

.navbar {
  min-height: 46px;
  padding: 0.2rem 0 !important;
  z-index: 999;
}

.bg-nav-body-darkgreen {
  background-color: $darkgreen;
}

.navbar-brand {
  img {
    width: 100%;
    max-width: 130px;
  }
}

.menuNavbar-organization-list {
  max-height: 300px;
  overflow-x: auto;
}

.navbar-nav {
  .nav-link {
    padding: 0.35rem;
    color: $extralight;
    text-transform: capitalize;
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
      color: $extralighttwo;
    }
  }
}

.header-user-dropdown {
  color: $white;
  background-color: $marunone;
  width: 24px;
  height: 24px;
  font-size: 0.9rem;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  border-radius: 3px;
  margin-right: 12px;

  .dropdown-menu[data-bs-popper] {
    right: 0;
    margin-top: 11px;
    left: auto;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .nav-link {
    font-weight: 600;
  }

  .dropdown-item {
    padding: 0.5rem 2rem;

    &:focus {
      color: #262626;
      text-decoration: none;
      background-color: $grayone;
    }

    &:hover {
      background-color: $grayone;
    }

    &:active {
      background-color: $aqua;
      color: $white;
    }
  }

  .dropdown-menu {
    font-size: 0.875rem;
    border-color: rgba(61, 92, 106, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 30px;
    border-radius: 0.3rem;

    .dropdown-menu .dropdown-menu-link {
      display: block;
      padding: 0.2rem 1rem;
      color: $darkgreenone;
    }
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255 255 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  padding: 0.13rem 0.4rem;
  font-size: 17px;
}

.img-cursur {
  cursor: pointer;
}

.header-custom-dropdown {
  .dropdown-toggle {
    &::after {
      content: inherit;
    }
  }

  .dropdown-menu[data-bs-popper] {
    left: inherit;
    right: 0;
  }

  .dropdown-menu {
    border-color: rgba(61, 92, 106, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 30px;

    .dropdown-item {
      padding: 0.7rem 1.8rem;
      color: $darkgreenone;
      font-size: 0.875rem;

      &:hover {
        color: #262626;
        background-color: $grayone;
      }

      &:focus {
        color: #262626;
        background-color: $grayone;
      }

      &.active,
      &:active {
        color: $white;
        background-color: $aqua;
      }
    }
  }
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: $extralight;
}

// @media (max-width: 1200px) {
//   .header-user-dropdown {
//     .dropdown-menu[data-bs-popper] {
//       left: -134px;
//     }
//   }
// }
// @media (max-width: 1200px) {
//   .header-user-dropdown {
//     .dropdown-menu[data-bs-popper] {
//       left: -134px;
//     }
//   }
// }

@media (max-width: 991.98px) {
  .header-user-dropdown {
    .dropdown-menu[data-bs-popper] {
      left: auto;
      right: auto;
    }
  }
}